import React from 'react';
// import { Link } from 'gatsby';
import { graphql } from 'gatsby';
import Layout from '../components/layout';

import './single-project.scss';


export default function Template({ data }) {
    const project = data.markdownRemark;
    const techList = project.frontmatter.tech;
    const techListItems = techList.map((listItem) =>
        <li key={listItem} className="project-tech-stack__item"><span>{listItem}</span></li>
    );

    return(
        <Layout>
            {/* <section className="project-hero" style={{backgroundImage: "url(" + project.frontmatter.image +")" }}> */}
            {/* <Link to="/">Back</Link> */}
            <section className="project-hero" style={{ backgroundImage: `url(${project.frontmatter.heroImage})` }}>
                <div className="project-title__container">
                    <h1 className="project-title">{project.frontmatter.title}</h1>
                </div>
            </section>
            <section className="project-detail">
                <div className="project-tech-stack">
                    <h3 className="project-tech-stack__title">Built with</h3>
                    <ul className="project-tech-stack__list">{techListItems}</ul>    
                </div>
                {/* <div className="project-photo">
                    <img src={project.frontmatter.image} alt=""/>
                </div> */}
                <div className="project-info">
                    <h3 className="project-info__title">Role</h3>
                    <p className="project-info__title-actual">{project.frontmatter.role}</p>
                    <h3 className="project-info__role">Live Link</h3>
                    <a href={project.frontmatter.liveLink}><p className="project-info__role-actual">View</p></a>
                </div>
            </section>
            <section className="project-summary" dangerouslySetInnerHTML={{__html: project.html}} />
            <section className="project-screenshot">
                {/* <img src={project.frontmatter.fullImage} alt=""/> */}
                <video className="project-screenshot__media" controls="controls" autoPlay loop={true}>
                    <source src={project.frontmatter.video} type="video/mp4"/>
                </video>
                <p>Video screen grab of {project.frontmatter.title}</p>
            </section>
        </Layout>
    )
}

export const ProjectQuery = graphql`
    query SingleProjectPath($path: String!) {
        markdownRemark(frontmatter: {path: { eq: $path } }) {
            rawMarkdownBody
            internal {
                content
            }
            html
            frontmatter {
                path
                title
                author
                date
                tech
                role
                liveLink
                image
                fullImage
                heroImage
                type
                video
            }
        }
    }
`