import React from 'react';
import Link from 'gatsby-link';
import './menu.scss';

const Menu = () =>  (
  <ul className='site-menu'>
    <li className='site-menu__item'><Link to='/'><h3>Home</h3></Link></li>
    {/* <li className='site-menu__item'><Link to='/about'>About</Link><span className='item-seperator'> /</span></li> */}
    <li className='site-menu__item'><Link to='/work/'><h3>Work</h3></Link></li>
    <li className='site-menu__item'><Link to='/blog/'><h3>Blog</h3></Link></li>
  </ul>
)

export default Menu;
